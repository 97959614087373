@import "./variables";

 // SP MAX 768
@mixin mqSp {
	@media screen and (max-width: $break-l) {
		@content;
	}
}

// TB 769 - 1023
@mixin mqTb{
	@media (min-width: ($break-m)+1) and (max-width: ($break-l)) {
		@content;
	}
}

// PC 769
@mixin mqPc {
	@media screen and (min-width: ($break-m)+1) {
		@content;
	}
}

@mixin mqPc-l {
	@media screen and (min-width: ($break-l)+1) {
		@content;
	}
}

@mixin clearfix() {
	&:after {
		content: " ";
		display: block;
		clear: both;
		visibility: hidden;
	}
}

@mixin text-ellipsis() {
	word-break: break-all;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// FLEX
@mixin flex-center-top(){
	display:flex;
	justify-content: center;
	align-items: flex-start;
}

@mixin flex-center-bottom(){
	display:flex;
	justify-content: center;
	align-items: flex-end;
}

@mixin flex-left-top(){
	display:flex;
	justify-content: flex-start;
	align-items: flex-start;
}

@mixin flex-left-bottom(){
	display:flex;
	justify-content: flex-start;
	align-items: flex-end;
}

@mixin flex-right-top(){
	display:flex;
	justify-content: flex-end;
	align-items: flex-start;
}

@mixin flex-between-top(){
	display:flex;
	justify-content: space-between;
	align-items: flex-start;
}

@mixin flex-between-bottom(){
	display:flex;
	justify-content: space-between;
	align-items: flex-end;
}

@mixin text-ellipsis() {
	word-break: break-all;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@function fslh-spacing($space,$fs,$lh){
	@return calc(#{$space} - ((#{$fs} * #{$lh}) - #{$fs}) / 2);
};

