@import './../../foundation/variables';
@import './../../foundation/mixin';


.p-top-mainVisual{
	&__link{
		&>img,
		&>picture{
			&::before{
				display: none;
			}
		}
		&:hover{
			@include mqPc {
				opacity: 1;
			}
			.p-top-mainVisual__image{
				picture,
				img{
					@include mqPc {
						transform: scale(1.02);
					}
				}
			}
		}
	}
	&__panel{
		flex: 1 1 100%;
		max-width: 100%;
		margin: 0;
		position: relative;
		overflow: unset;
		@include mqPc {
			flex: 0 1 50%;
			max-width: 50%;
		}
		&:nth-of-type(1){
			@include mqPc {
				flex: 1 1 100%;
				width: 100%;
				max-width: 100%;
			}
		}

		picture,
		img{
			width: 100%;
			max-width: 100%;
			height: auto;
		}
		&:nth-of-type(4){
			.p-top-mainVisual__frame{
				@include mqPc {
					top: -1px;
				}
			}
		}
		&:nth-of-type(5){
			.p-top-mainVisual__frame{
				@include mqPc {
				height: 101%;
				max-width: unset;
				width: unset;
				}
				img{
					@include mqPc {
					height: 100%;
					}
				}
			}
		}
	}
	&__frame{
		display: none;
		@include mqPc {
			display: block;
			position: absolute;
			width: 100%;
			height: auto;
			left: 0;
			top: 0;
			z-index: 1;
		}
	}
	&__image{
		overflow: hidden;

		img,
		picture{
			@include mqPc {
				transition: transform .3s ease-in;
			}
		}

	}
}


.-is-top,
.top{

	.l-main {
		@include mqPc {
			padding-top: 0px;
		}
	}
	.l-container{
		margin-top: 15px;
		padding-top: 11px;
		@include mqPc {
			margin-top: 29px;
			padding-bottom: 19px;
			padding-top: 33px;
		}
	}
	.c-news .swiper-wrapper {
    padding-bottom: 25px;
		@include mqPc {
			padding-bottom: 36px;
		}
	}
	.c-news .swiper-pagination .swiper-pagination-bullet{
		margin-left: 13px;
		margin-right: 13px;

		@include mqPc {
			margin-right: 5px;
			margin-left: 5px;
		}
	}
	.c-news__category{
		letter-spacing: normal;
		@include mqPc {
			width: 125px;
		}
	}
	.c-news+.p-media{
		margin-top: 23px;
		@include mqPc {
			margin-top: 34px;
		}
	}
	.c-news__item p{
		margin-top: 1px;
		@include mqPc {
			margin-top: 0;
		}
	}
	.c-media__heading+.c-media__text{
		letter-spacing: normal;
	}
	.p-media{
		width: calc(100% - 25px);
		padding: 8px 7px 11px 7px;
		@include mqPc {
			width: calc(100% - 75px);
			padding: 12px 14px 10px;
		}
	}
	.p-media__image{
		margin-top: 8px;
		@include mqPc {
			margin-top: 0;
			flex: 0 1 324px;
			max-width: 324px;
		}
	}
	.c-media__text{
		@include mqPc {
			margin-top: 3px;
			font-size: 1.2rem;
			line-height: 1.93;
		}
	}
	.p-media__contents{
		@include mqPc {
			flex: 0 1 calc(100% - 324px);
			max-width: calc(100% - 324px);
			padding-left: 26px;
		}
	}
	.c-media__heading{
		@include mqPc {
			margin-top: -7px;
		}
	}
	.c-sns{
		padding-top: 45px;
		@include mqPc {
			padding-top: 34px;
		}
	}
	.c-sns__list{
		width: 79.37%;
	}
	.c-sns__item {
    width: 23%;
		@include mqPc {
			width: 46px;
			margin: 0 17px;
		}
	}
	.c-top-photoCopy {
    width: calc(100% - 23px);
    margin-top: 50px;
		@include mqPc {
			width: calc(100% - 70px);
			margin-top: 33px;
		}
		p{
			line-height: 1.5;
		}
	}
	.l-footer .footer__list li{
		margin-top: 22px;
		@include mqPc {
			margin-top: 0px;
		}
	}
	.l-footer .l-footer__inner{
		padding-top: 9px;
		@include mqPc {
			padding-top: 24px;
			padding-right: 60px;
			padding-bottom: 45px;
			padding-left: 60px;
		}
	}
}


.c-top-photoCopy{
	@include mqPc {
	width: calc(100% - 54px);
	margin-top: 31px;
	}
	p{
		@include mqPc {
			font-size: 1.1rem;
			line-height: 1.72;
		}
	}
}


